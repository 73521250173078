/* eslint-disable import/no-cycle */
import React from 'react';
import Link from 'next/link';
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import UserIcon from '../icons/UserIcon';
import { useUser } from '../../hooks';

export default function Navbar() {
  const [user] = useUser();

  return (
    <nav className="absolute top-0 left-0 w-full z-10 bg-transparent lg:flex-row lg:flex-no-wrap lg:justify-start flex items-center p-4 text-gray-900">
      <div className="w-full mx-auto items-center flex justify-between lg:flex-no-wrap flex-wrap lg:px-10 px-4">
        <h2 className="font-semibold text-primary-300 hidden lg:inline-block">
          Bienvenido, {user?.name}
        </h2>

        <ul className="flex-col lg:flex-row list-none items-center hidden lg:flex lg:space-x-2">
          <NotificationDropdown />
          <Link href="/app/profile">
            <a className="text-neutral-400 hover:text-neutral-600">
              <UserIcon className="fill-current w-7 h-7" />
            </a>
          </Link>
          <UserDropdown />
        </ul>
      </div>
    </nav>
  );
}
