import React from 'react';
import Link from 'next/link';
import DownArrowIcon from '../icons/DownArrowIcon';
import UpArrowIcon from '../icons/UpArrowIcon copy';

interface SidebarItemProps {
  href?: string;
  as?: string;
  icon?: React.ReactChild;
  text: string;
  isCollapsible?: boolean;
  active?: boolean;
  subLinks?: Array<{
    href?: string;
    as?: string;
    text?: string;
  }>;
}

export default function SidebarItem({
  href,
  as,
  icon,
  text,
  active = false,
  isCollapsible = false,
  subLinks = [],
}: SidebarItemProps) {
  const [isOpen, setOpen] = React.useState(false);
  const toggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setOpen(!isOpen);
  };
  if (!isCollapsible) {
    return (
      <li className="items-center px-6 border-transparent hover:bg-background-200 border-l-4 hover:border-primary-300">
        <Link href={href} as={as}>
          <a
            className={`text-xs uppercase py-3 font-semibold flex flex-row flex-wrap w-full hover:text-primary-300 ${
              active ? 'text-primary-300' : 'text-neutral-400'
            }`}
          >
            {icon} <span className="ml-2 my-auto">{text}</span>
          </a>
        </Link>
      </li>
    );
  }

  return (
    <li className="items-center px-6 border-transparent hover:bg-background-200 border-l-4 hover:border-primary-300">
      <button
        type="button"
        className={`p-0 m-0 mb-1 bg-transparent focus:outline-none outline-none text-xs uppercase py-3 font-semibold flex flex-row flex-wrap w-full hover:text-primary-300 ${
          active ? 'text-primary-300' : 'text-neutral-400'
        }`}
        onClick={toggle}
      >
        {icon}
        <span className="ml-2 my-auto">{text}</span>
        {isOpen ? (
          <UpArrowIcon className="ml-auto w-5 h-5 text-neutral-400 hover:text-primary-300" />
        ) : (
          <DownArrowIcon className="ml-auto w-5 h-5 text-neutral-400" />
        )}
      </button>
      <ul
        className={`${
          isOpen ? 'flex' : 'hidden'
        } lg:flex-col lg:min-w-full flex flex-col list-none`}
      >
        {subLinks.map((link) => (
          <li className="items-center" key={link.as}>
            <Link href={link.href} as={link.as}>
              <a className="text-neutral-400 text-xs uppercase py-3 font-semibold block hover:text-primary-300">
                {link.text}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}
