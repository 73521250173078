import React from 'react';
import CloseIcon from '../icons/CloseIcon';

interface ToastProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type?: 'success' | 'error' | 'warning' | 'info';
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function Toast({
  type = 'info',
  id = '',
  onDelete,
  children,
  ...rest
}: ToastProps) {
  switch (type) {
    case 'success':
      return (
        <div
          className="max-w-xs mb-3 bg-success-300 rounded text-white w-full mx-auto px-4 py-2"
          role="alert"
          style={{ zIndex: 200 }}
          {...rest}
        >
          <div className="w-full flex flex-row flex-wrap">
            <div className="flex flex-row flex-wrap flex-grow pl-1">
              <span className="w-full flex flex-row flex-wrap">
                <p className="font-semibold text-white">¡Éxito!</p>
                <button
                  type="button"
                  className="ml-auto p-0 bg-transparent focus:outline-none outline-none"
                  data-id={id}
                  onClick={onDelete}
                >
                  <CloseIcon className="w-3 h-3" />
                </button>
              </span>

              {typeof children === 'string' ? (
                <p className="text-sm">{children}</p>
              ) : (
                children
              )}
            </div>
          </div>
        </div>
      );
    case 'error':
      return (
        <div
          className="max-w-xs mb-3 bg-danger-300 rounded text-white w-full mx-auto px-4 py-2"
          role="alert"
          style={{ zIndex: 200 }}
          {...rest}
        >
          <div className="w-full flex flex-row flex-wrap">
            <div className="flex flex-row flex-wrap flex-grow pl-1">
              <span className="w-full flex flex-row flex-wrap">
                <p className="font-semibold text-white">¡Error!</p>
                <button
                  type="button"
                  className="ml-auto p-0 bg-transparent focus:outline-none outline-none"
                  data-id={id}
                  onClick={onDelete}
                >
                  <CloseIcon className="w-3 h-3" />
                </button>
              </span>

              {typeof children === 'string' ? (
                <p className="text-sm">{children}</p>
              ) : (
                children
              )}
            </div>
          </div>
        </div>
      );
    case 'warning':
      return (
        <div
          className="max-w-xs mb-3 bg-warning-300 rounded text-white w-full mx-auto px-4 py-2 shadow-md"
          role="alert"
          style={{ zIndex: 200 }}
          {...rest}
        >
          <div className="w-full flex flex-row flex-wrap">
            <div className="flex flex-row flex-wrap flex-grow pl-1">
              <span className="w-full flex flex-row flex-wrap">
                <p className="font-semibold text-white">¡Cuidado!</p>
                <button
                  type="button"
                  className="ml-auto p-0 bg-transparent focus:outline-none outline-none"
                  data-id={id}
                  onClick={onDelete}
                >
                  <CloseIcon className="w-3 h-3" />
                </button>
              </span>

              {typeof children === 'string' ? (
                <p className="text-sm">{children}</p>
              ) : (
                children
              )}
            </div>
          </div>
        </div>
      );
    case 'info':
      return (
        <div
          className="max-w-xs mb-3 bg-secondary-300 rounded text-white w-full mx-auto px-4 py-2"
          role="alert"
          style={{ zIndex: 200 }}
          {...rest}
        >
          <div className="w-full flex flex-row flex-wrap">
            <div className="flex flex-row flex-wrap flex-grow pl-1">
              <span className="w-full flex flex-row flex-wrap">
                <p className="font-semibold text-white">¡Información!</p>
                <button
                  type="button"
                  className="ml-auto p-0 bg-transparent focus:outline-none outline-none"
                  data-id={id}
                  onClick={onDelete}
                >
                  <CloseIcon className="w-3 h-3" />
                </button>
              </span>

              {typeof children === 'string' ? (
                <p className="text-sm">{children}</p>
              ) : (
                children
              )}
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
}
