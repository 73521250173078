import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    slug
    name
    email
    privilege
    phone
    active
    source
  }
`;

export const CategoryFragment = gql`
  fragment CategoryFragment on Category {
    _id
    slug
    name
    icon
    subcategories {
      _id
      name
      icon
    }
    active
  }
`;

export const OptionFragment = gql`
  fragment OptionFragment on Option {
    _id
    title
    type
    minChoices
    maxChoices
    items {
      _id
      name
      price
      active
    }
    active
  }
`;

export const ProductFragment = gql`
  fragment ProductFragment on Product {
    _id
    slug
    code
    name
    priority
    category {
      _id
      name
    }
    subcategory
    description
    price
    compareAtPrice
    tags
    options {
      ...OptionFragment
    }
    time
    timeGrowthRate
    limitPerHour
    limitPerOrder
    photos
    active
    available
    taxes {
      _id
      name
      value
    }
  }
  ${OptionFragment}
`;

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    _id
    state
    city
    municipality
    neighborhood
    street
    street2
    house
    reference
    type
  }
`;

export const ChargeFragment = gql`
  fragment ChargeFragment on Charge {
    _id
    reference
    bank
    method
    amount
    commission
    createdAt
    currency {
      _id
      name
      rate
      symbol
    }
    taxes {
      name
      value
    }
  }
`;

export const ClientFragment = gql`
  fragment ClientFragment on Client {
    _id
    phone
    promos {
      code
      rate
    }
    addresses {
      ...AddressFragment
    }
    orders {
      _id
    }
    user {
      ...UserFragment
    }
  }
  ${UserFragment}
  ${AddressFragment}
`;

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    _id
    code
    createdAt
    status
    stage
    source
    deliveryMethod
    immediate
    schedule {
      date
      hour
      minute
    }
    paid
    eta {
      date
      hour
      minute
    }
    schedule {
      date
      hour
      minute
    }
    preparationTime
    subtotal
    taxes {
      name
      value
    }
    extraFees
    commission
    discount
    total
    products {
      _id
      code
      name
      photo
      price
      time
      quantity
      taxes {
        name
        value
      }
      options {
        title
        option
        price
      }
      product {
        _id
        description
      }
    }
    shipping {
      name
      municipality
      neighborhoods
      price
      time
    }
    client {
      ...UserFragment
      dni
      dniType
      active
      client {
        phone
        addresses {
          ...AddressFragment
        }
        active
        shopCart {
          code
        }
      }
    }
    address {
      ...AddressFragment
    }
    phone
    rider {
      ...UserFragment
    }
    charge {
      ...ChargeFragment
    }
    notes {
      _id
      fromClient
      text
      target
      active
    }
    history {
      message
      type
      createdAt
    }
  }
  ${ChargeFragment}
  ${UserFragment}
  ${AddressFragment}
`;

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PaginationInfo {
    pageCount
    itemCount
    hasNextPage
    hasPreviousPage
  }
`;

export const DeliveryNoteFragment = gql`
  fragment DeliveryNoteFragment on DeliveryNote {
    _id
    controlNumber
    paid
    charge {
      ...ChargeFragment
    }
    order {
      ...OrderFragment
    }
    createdAt
  }
  ${ChargeFragment}
  ${OrderFragment}
`;
