import React from 'react';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  notMargin?: boolean;
  color?: string;
  outline?: boolean;
}

export default function Button({
  children,
  type,
  disabled,
  className,
  notMargin = false,
  color = 'primary',
  outline = false,
  ...rest
}: ButtonProps) {
  if (color === 'success') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`py-1.5 px-8 font-bold rounded-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
          notMargin ? '' : 'mr-2'
        } ${
          outline
            ? 'bg-transparent border border-success-300 text-success-300 hover:border-success-200 hover:text-success-200 '
            : 'bg-success-300 hover:bg-success-200 active:bg-success-400 text-font-white '
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (color === 'danger') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`py-1.5 px-8 font-bold rounded-md focus:outline-none  disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
          notMargin ? '' : 'mr-2'
        } ${
          outline
            ? 'bg-transparent border border-danger-300 text-danger-300 hover:border-danger-200 hover:text-danger-200'
            : 'bg-danger-300 hover:bg-danger-200 active:bg-danger-400 text-font-white'
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (color === 'warning') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`py-1.5 px-8 font-bold rounded-md focus:outline-none  disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
          notMargin ? '' : 'mr-2'
        } ${
          outline
            ? 'bg-transparent border border-warning-300 text-warning-300 hover:border-warning-200 hover:text-warning-200'
            : 'bg-warning-300 hover:bg-warning-200 active:bg-warning-400 text-font-white'
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`py-1.5 px-8 font-bold  rounded-md focus:outline-none  disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
        notMargin ? '' : 'mr-2'
      } ${
        outline
          ? 'bg-transparent border border-primary-300 text-primary-300 hover:border-primary-200 hover:text-primary-200 '
          : 'bg-primary-300 hover:bg-primary-200 active:bg-primary-400 text-font-white '
      }`}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
