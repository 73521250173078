import { gql } from '@apollo/client';
import { AddressFragment } from './fragments';

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput) {
    signIn(data: $data) {
      user {
        name
        email
        _id
        privilege
      }
    }
  }
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($data: CreateUserInput!) {
    createUser(data: $data) {
      _id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput!
  ) {
    updateUser(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CREATE_CATEGORY($record: CreateOneCategoryInput!) {
    createCategory(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UPDATE_CATEGORY(
    $record: UpdateOneCategoryInput!
    $filter: FilterUpdateOneCategoryInput!
  ) {
    updateCategory(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const SIGN_S3 = gql`
  mutation SIGN_S3($data: SignS3Input!) {
    signS3(data: $data) {
      signedRequest
      url
    }
  }
`;

export const CREATE_TAX = gql`
  mutation CREATE_TAX($record: CreateOneTaxInput!) {
    createTax(record: $record) {
      recordId
    }
  }
`;

export const UPDATE_TAX = gql`
  mutation UPDATE_TAX(
    $record: UpdateOneTaxInput!
    $filter: FilterUpdateOneTaxInput!
  ) {
    updateTax(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CREATE_PRODUCT($record: CreateOneProductInput!) {
    createProduct(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UPDATE_PRODUCT(
    $record: UpdateOneProductInput!
    $filter: FilterUpdateOneProductInput
  ) {
    updateProduct(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_OPTION = gql`
  mutation CREATE_OPTION($record: CreateOneOptionInput!) {
    createOption(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_OPTION = gql`
  mutation UPDATE_OPTION(
    $record: UpdateOneOptionInput!
    $filter: FilterUpdateOneOptionInput
  ) {
    updateOption(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_PROMO_CODE = gql`
  mutation CREATE_PROMO_CODE($record: CreateOnePromoCodeInput!) {
    createPromoCode(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_PROMO_CODE = gql`
  mutation UPDATE_PROMO_CODE(
    $record: UpdateOnePromoCodeInput!
    $filter: FilterUpdateOnePromoCodeInput!
  ) {
    updatePromoCode(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_CURRENCY = gql`
  mutation CREATE_CURRENCY($record: CreateOneCurrencyInput!) {
    createCurrency(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_CURRENCY = gql`
  mutation UPDATE_CURRENCY(
    $record: UpdateOneCurrencyInput!
    $filter: FilterUpdateOneCurrencyInput!
  ) {
    updateCurrency(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_SHIPPING = gql`
  mutation CREATE_SHIPPING($record: CreateOneShippingInput!) {
    createShipping(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_SHIPPING = gql`
  mutation UPDATE_SHIPPING(
    $record: UpdateOneShippingInput!
    $filter: FilterUpdateOneShippingInput!
  ) {
    updateShipping(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

// export const CREATE_ORDER = gql`
//   mutation CREATE_ORDER($data: AdminCreateOrderInput!) {
//     adminCreateOrder(data: $data) {
//       _id
//     }
//   }
// `;

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($data: AdminCreateOrderInput!) {
    adminCreateOrder(data: $data) {
      message {
        success
        err
      }
      order {
        _id
      }
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation CREATE_CLIENT($data: CreateClientInput!) {
    createClient(data: $data) {
      _id
      phone
      user {
        _id
        name
        email
        dni
        dniType
      }
      addresses {
        ...AddressFragment
      }
    }
  }
  ${AddressFragment}
`;

export const UPDATE_ORDER = gql`
  mutation UPDATE_ORDER($data: UpdateOrderInput!) {
    updateOrder(data: $data) {
      _id
      status
      stage
      paid
    }
  }
`;

export const UPDATE_ORDER_RIDER = gql`
  mutation UPDATE_ORDER_RIDER($data: UpdateOrderRiderInput!) {
    updateOrderRider(data: $data) {
      success
      err
    }
  }
`;

export const MODIFY_ORDER = gql`
  mutation MODIFY_ORDER($data: ModifyOrderInput!) {
    modifyOrder(data: $data) {
      _id
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CREATE_NOTE($data: CreateNoteInput!) {
    createNote(data: $data) {
      _id
      active
      fromClient
      target
      text
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UPDATE_NOTE($data: UpdateNoteInput) {
    updateNote(data: $data) {
      _id
      active
      fromClient
      target
      text
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DELETE_NOTE($data: DeleteNoteInput!) {
    deleteNote(data: $data) {
      _id
      active
      fromClient
      target
      text
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation CREATE_QUESTION($record: CreateOneQuestionInput!) {
    createQuestion(record: $record) {
      record {
        _id
        title
        text
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation CREATE_QUESTION(
    $filter: FilterUpdateOneQuestionInput!
    $record: UpdateOneQuestionInput!
  ) {
    updateQuestion(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DELETE_QUESTION($filter: FilterRemoveOneQuestionInput!) {
    deleteQuestion(filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation UPDATE_CONFIG($updateData: UpdateSettingInput) {
    updateSetting(data: $updateData) {
      carouselImages {
        title
        description
        url
      }
      orderLifespan
      closeHour
      openHour
      pickupAddress
      supportUrl
    }
  }
`;

export const UPDATE_TERMS_OF_SERVICE = gql`
  mutation UPDATE_TERMS_OF_SERVICE(
    $record: UpdateOneTermsOfServiceInput!
    $filter: FilterUpdateOneTermsOfServiceInput!
  ) {
    updateTermsOfService(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_PLUGIN = gql`
  mutation UPDATE_PLUGIN(
    $record: UpdateOnePluginInput!
    $filter: FilterUpdateOnePluginInput!
  ) {
    updatePlugin(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation CREATE_PAYMENT_METHOD($record: CreateOnePaymentMethodInput!) {
    createPaymentMethod(record: $record) {
      record {
        _id
        title
      }
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation CREATE_PAYMENT_METHOD(
    $filter: FilterUpdateOnePaymentMethodInput!
    $record: UpdateOnePaymentMethodInput!
  ) {
    updatePaymentMethod(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation DELETE_PAYMENT_METHOD($filter: FilterRemoveOnePaymentMethodInput!) {
    deletePaymentMethod(filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const UPLOAD_MANY_PRODUCTS = gql`
  mutation UPLOAD_MANY_PRODUCTS($data: UploadManyProductsInput!) {
    uploadManyProducts(data: $data) {
      success
      err
      msg
    }
  }
`;

export const CHANGE_OLD_PASSWORD = gql`
  mutation CHANGE_OLD_PASSWORD($data: ChangeOldPasswordInput!) {
    changeOldPassword(data: $data) {
      success
      err
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CREATE_ADDRESS($data: CreateAddressInput!) {
    createAddress(data: $data) {
      ...AddressFragment
    }
  }
  ${AddressFragment}
`;

export const UPDATE_ADDRESS = gql`
  mutation UPDATE_ADDRESS(
    $record: UpdateOneAddressInput!
    $filter: FilterUpdateOneAddressInput
  ) {
    updateAddress(record: $record, filter: $filter) {
      record {
        ...AddressFragment
      }
    }
  }
  ${AddressFragment}
`;

export const REPORT_PROBLEM = gql`
  mutation REPORT_PROBLEM(
    $userId: String!
    $orderId: String!
    $adminId: String!
  ) {
    reportProblemOrder(userId: $userId, orderId: $orderId, adminId: $adminId) {
      code
    }
  }
`;

export const CREATE_CHARGE = gql`
  mutation CREATE_CHARGE($data: CreateProfileChargeInput!) {
    createCharge(data: $data) {
      _id
    }
  }
`;

export const DELETE_CHARGE = gql`
  mutation DELETE_CHARGE($data: DeleteChargeInput!) {
    deleteCharge(data: $data) {
      _id
    }
  }
`;

export const UPDATE_ORDER_DELIVERY_METHOD = gql`
  mutation UPDATE_ORDER_DELIVERY_METHOD($data: UpdateOrderDeliveryMethodInput) {
    updateOrderDeliveryMethod(data: $data) {
      success
      err
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;
