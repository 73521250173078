/* eslint-disable import/no-cycle */
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useUser } from '../../hooks';
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import SidebarItem from './SidebarItem';
import routes from '../../routes';
import UserIcon from '../icons/UserIcon';
import CloseIcon from '../icons/CloseIcon';

function Sidebar() {
  const [user] = useUser();
  const router = useRouter();
  const [collapseShow, setCollapseShow] = React.useState(false);

  return (
    <nav className="lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-no-wrap lg:overflow-hidden shadow-gray bg-background-100 text-white flex flex-wrap items-center justify-between relative lg:w-64 z-10 py-4">
      <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        {/* Toggler */}
        <button
          className="cursor-pointer text-neutral-600 ml-6 lg:hidden px-3 py-1 leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setCollapseShow(true);
          }}
        >
          <svg fill="currentColor" className="w-6 h-6" viewBox="0 0 20 20">
            <path
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
              fillRule="evenodd"
            />
          </svg>
        </button>
        {/* Brand */}
        <Link href="/app">
          <div className="hidden lg:flex px-5 pt-3 cursor-pointer items-center">
            <img src="/logo.png" alt="Logo" className="w-16 mx-auto" />
          </div>
        </Link>
        {/* User */}
        <ul className="lg:hidden items-center flex flex-wrap list-none">
          <li className="hidden sm:inline-block relative pr-2">
            <NotificationDropdown />
          </li>
          <li className="pr-2">
            <Link href="/app/profile">
              <a className="text-neutral-400 hover:text-neutral-600">
                <UserIcon className="fill-current w-7 h-7" />
              </a>
            </Link>
          </li>
          <li className="inline-block relative pr-6">
            <UserDropdown />
          </li>
        </ul>
        {/* Collapse */}
        <div
          className={`lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none shadow-gray absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 ${
            collapseShow
              ? 'bg-background-100 m-2 py-3 px-6 lg:px-0 lg:m-0'
              : 'hidden'
          }`}
        >
          {/* Collapse header */}
          <div className="lg:min-w-full lg:hidden block pb-4 border-b border-solid border-gray-200 px-6">
            <div className="flex flex-wrap">
              <div className="w-6/12">
                <Link href="/app">
                  <img src="/logo.png" alt="Logo" className="h-12" />
                </Link>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-neutral-400 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={(e) => {
                    e.preventDefault();
                    setCollapseShow(false);
                  }}
                >
                  <CloseIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
          {/* Navigation */}
          <ul className="lg:flex-col lg:min-w-full flex flex-col list-none mt-4">
            {routes.map((route) =>
              [...route.privilege].includes(user?.privilege) ? (
                <SidebarItem
                  {...route}
                  key={route.text}
                  active={router.route === route.href}
                />
              ) : null
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
