export const USER_PRIVILEGES = {
  CLIENT: 0,
  ADMIN: 1,
  EDIT: 2,
  READ: 3,
  RIDER: 4,
  KITCHEN: 5,
  DISPATCH: 6,
  CUSTOMERSERVICE: 7,
  KITCHEN_MANAGER: 8,
  BILLING_MANAGER: 9,
};
