import React from 'react';

export const usePagination = (search: string = '', initialPage: number = 1) => {
  const [page, setPage] = React.useState(initialPage);

  React.useEffect(() => {
    setPage(1);
  }, [search]);

  const nextPage = () => setPage((prev) => prev + 1);
  const prevPage = () => setPage((prev) => (page > 1 ? prev - 1 : prev));
  return {
    page,
    nextPage,
    prevPage,
    setPage,
  };
};
