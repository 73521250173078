import { gql } from '@apollo/client';
import {
  AddressFragment,
  ChargeFragment,
  DeliveryNoteFragment,
  OptionFragment,
  OrderFragment,
  PageInfoFragment,
  ProductFragment,
  UserFragment,
} from './fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_ORDERS = gql`
  query GET_ORDERS($skip: Int, $limit: Int, $filter: FilterFindManyOrderInput) {
    orders(skip: $skip, limit: $limit, filter: $filter) {
      _id
      code
      status
      stage
      subtotal
      commission
      extraFees
      total
      createdAt
      deliveryMethod
      taxes {
        value
      }
      schedule {
        date
        hour
        minute
      }
      client {
        name
        dniType
        dni
      }
      rider {
        name
      }
      charge {
        method
        bank
      }
      notes {
        _id
        fromClient
        text
      }
    }
  }
`;

export const DELIVERY_NOTE_PAGINATION = gql`
  query DELIVERY_NOTE_PAGINATION($data: PaginationInput) {
    deliveryNotePagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        ...DeliveryNoteFragment
      }
    }
  }
  ${PageInfoFragment}
  ${DeliveryNoteFragment}
`;

export const ORDER_PAGINATION = gql`
  query ORDER_PAGINATION($data: PaginationInput) {
    orderPagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        code
        status
        stage
        subtotal
        commission
        extraFees
        total
        createdAt
        deliveryMethod
        taxes {
          value
        }
        schedule {
          date
          hour
          minute
        }
        client {
          name
          dniType
          dni
        }
        rider {
          name
        }
        charge {
          method
          bank
        }
        notes {
          _id
          fromClient
          text
        }
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS($filter: FilterFindManyProductInput, $limit: Int) {
    products(filter: $filter, limit: $limit) {
      _id
      name
      active
      photos
      code
      subcategory
      price
      time
      limitPerHour
      limitPerOrder
      timeGrowthRate
      taxes {
        name
      }
      category {
        _id
        name
      }
    }
  }
`;

export const PRODUCT_PAGINATION = gql`
  query PRODUCT_PAGINATION($data: PaginationInput) {
    productPaginationAdmin(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        name
        active
        photos
        code
        subcategory
        price
        time
        limitPerHour
        limitPerOrder
        timeGrowthRate
        taxes {
          name
        }
        category {
          _id
          name
        }
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_USER = gql`
  query GET_USER($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_USERS = gql`
  query GET_USERS($skip: Int, $limit: Int) {
    users(
      skip: $skip
      limit: $limit
      filter: { _operators: { privilege: { gt: 0 } } }
    ) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const USER_PAGINATION = gql`
  query USER_PAGINATION($data: PaginationInput) {
    userPagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        ...UserFragment
      }
    }
  }
  ${PageInfoFragment}
  ${UserFragment}
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($skip: Int, $limit: Int) {
    categories(skip: $skip, limit: $limit) {
      _id
      name
      active
    }
  }
`;

export const CATEGORY_PAGINATION = gql`
  query CATEGORY_PAGINATION($data: PaginationInput) {
    categoryPagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        name
        active
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_CATEGORY = gql`
  query GET_CATEGORY($filter: FilterFindOneCategoryInput!) {
    category(filter: $filter) {
      _id
      name
      icon
      code
      subcategories {
        _id
        name
        icon
      }
      active
    }
  }
`;

export const GET_PROMO_CODE = gql`
  query GET_PROMO_CODE($filter: FilterFindOnePromoCodeInput!) {
    promoCode(filter: $filter) {
      _id
      name
      code
      discount
      type
      expirationDate
      active
    }
  }
`;

export const GET_PROMO_CODES = gql`
  query GET_PROMO_CODES($skip: Int, $limit: Int) {
    promoCodes(skip: $skip, limit: $limit) {
      _id
      name
      code
      active
    }
  }
`;

export const PROMO_CODE_PAGINATION = gql`
  query PROMO_CODE_PAGINATION($data: PaginationInput) {
    promoCodePagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        name
        code
        active
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_CREATE_PRODUCT_INFO = gql`
  query GET_CREATE_PRODUCT_INFO {
    categories(filter: { active: true }, limit: 100000) {
      _id
      code
      name
      subcategories {
        _id
        name
      }
    }
    taxes(filter: { active: true }) {
      _id
      name
    }
    options(filter: { active: true }) {
      ...OptionFragment
    }
  }
  ${OptionFragment}
`;

export const GET_PRODUCT = gql`
  query GET_PRODUCT($filter: FilterFindOneProductInput) {
    categories(filter: { active: true }, limit: 1000) {
      _id
      name
      subcategories {
        _id
        name
      }
    }
    taxes(filter: { active: true }) {
      _id
      name
    }
    options(filter: { active: true }) {
      ...OptionFragment
    }
    product(filter: $filter) {
      ...ProductFragment
    }
  }
  ${ProductFragment}
  ${OptionFragment}
`;

export const GET_CURRENCY = gql`
  query GET_CURRENCY($filter: FilterFindOneCurrencyInput) {
    currency(filter: $filter) {
      _id
      name
      symbol
      rate
      active
    }
  }
`;

export const GET_CURRENCIES = gql`
  query GET_CURRENCIES($skip: Int, $limit: Int) {
    currencies(skip: $skip, limit: $limit) {
      _id
      name
      symbol
      active
    }
  }
`;

export const CURRENCY_PAGINATION = gql`
  query CURRENCY_PAGINATION(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCurrencyInput
    $sort: SortFindManyCurrencyInput
  ) {
    currencyPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        name
        symbol
        active
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_TAX = gql`
  query GET_TAX($filter: FilterFindOneTaxInput!) {
    tax(filter: $filter) {
      _id
      name
      active
      value
      createdAt
    }
  }
`;

export const GET_TAXES = gql`
  query GET_TAXES {
    taxes {
      _id
      name
      active
      value
      createdAt
    }
  }
`;

export const TAX_PAGINATION = gql`
  query TAX_PAGINATION($data: PaginationInput) {
    taxPagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        name
        active
        value
        createdAt
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_SHIPPING = gql`
  query GET_SHIPPING($filter: FilterFindOneShippingInput!) {
    shipping(filter: $filter) {
      _id
      slug
      name
      municipality
      neighborhoods
      price
      time
      active
    }
  }
`;

export const GET_SHIPPINGS = gql`
  query GET_SHIPPINGS($skip: Int, $limit: Int) {
    shippings(skip: $skip, limit: $limit) {
      _id
      name
      municipality
      neighborhoods
      active
    }
  }
`;

export const SHIPPING_PAGINATION = gql`
  query SHIPPING_PAGINATION($data: PaginationInput) {
    shippingPagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        name
        municipality
        neighborhoods
        active
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_OPTIONS = gql`
  query GET_OPTIONS($skip: Int, $limit: Int) {
    options(skip: $skip, limit: $limit) {
      _id
      title
      active
    }
  }
`;

export const OPTION_PAGINATION = gql`
  query OPTION_PAGINATION($data: PaginationInput) {
    optionPagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        title
        active
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_OPTION = gql`
  query GET_OPTION($filter: FilterFindOneOptionInput!) {
    option(filter: $filter) {
      ...OptionFragment
    }
  }
  ${OptionFragment}
`;

export const GET_CREATE_ORDER_INFO = gql`
  query GET_CREATE_ORDER_INFO($limit: Int) {
    products(
      filter: { active: true, available: true }
      limit: $limit
      sort: NAME_ASC
    ) {
      ...ProductFragment
    }
    shippings(filter: { active: true }, limit: $limit) {
      _id
      name
      municipality
      neighborhoods
      time
      price
    }
    currencies(limit: $limit) {
      _id
      name
      symbol
      rate
    }
    paymentMethods(limit: $limit) {
      _id
      title
      note
      commission
      active
      automated
      paymentData {
        _id
        name
        value
      }
      currency {
        _id
        name
      }
      taxes {
        _id
        name
        value
      }
    }
  }
  ${ProductFragment}
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      _id
      name
      email
      dni
      dniType
      source
      client {
        _id
        phone
        orders {
          _id
        }
        addresses {
          ...AddressFragment
        }
      }
    }
  }
  ${AddressFragment}
`;

export const GET_USER_BY_EMAIL_OR_DNI = gql`
  query GET_USER_BY_EMAIL_OR_DNI($data: GetUserByEmailOrDniInput) {
    getUserByEmailOrDni(data: $data) {
      _id
      phone
      user {
        _id
        name
        email
        dni
        dniType
      }
      addresses {
        ...AddressFragment
      }
      shopCart {
        code
      }
    }
  }
  ${AddressFragment}
`;

export const GET_CLIENT_ORDERS = gql`
  query GET_CLIENT_ORDERS($data: GetClientOrdersInput!) {
    getClientOrders(data: $data) {
      _id
      client {
        name
      }
      charge {
        method
      }
      status
      stage
      total
      createdAt
    }
  }
`;

export const GET_CLIENTS = gql`
  query GET_CLIENTS(
    $skip: Int
    $limit: Int
    $filter: FilterFindManyClientInput
  ) {
    clients(skip: $skip, limit: $limit, filter: $filter) {
      _id
      phone
      createdAt
      user {
        name
        active
        email
        dni
        dniType
        _id
      }
      shopCart {
        _id
      }
      orders {
        _id
      }
    }
  }
`;

export const CLIENT_PAGINATION = gql`
  query CLIENT_PAGINATION($data: PaginationInput) {
    clientPagination(data: $data) {
      count
      pageInfo {
        ...PageInfoFragment
      }
      items {
        _id
        phone
        createdAt
        user {
          name
          active
          email
          dni
          dniType
          _id
        }
        shopCart {
          _id
        }
        orders {
          _id
        }
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_ORDER = gql`
  query GET_ORDER(
    $filter: FilterFindOneOrderInput!
    $deliveryNoteFilter: FilterFindOneDeliveryNoteInput
  ) {
    order(filter: $filter) {
      ...OrderFragment
    }
    deliveryNote(filter: $deliveryNoteFilter) {
      controlNumber
    }
    currencies {
      _id
      name
      symbol
      rate
      active
    }
    paymentMethods {
      _id
      title
      note
      commission
      active
      automated
      paymentData {
        _id
        name
        value
      }
      currency {
        _id
        name
      }
      taxes {
        _id
        name
        value
      }
    }
  }
  ${OrderFragment}
`;

export const GET_MODIFY_PRODUCTS = gql`
  query GET_MODIFY_PRODUCTS($filter: FilterFindManyProductInput, $limit: Int) {
    products(filter: $filter, limit: $limit, sort: NAME_ASC) {
      ...ProductFragment
    }
  }
  ${ProductFragment}
`;

export const GET_RIDERS = gql`
  query GET_RIDERS($skip: Int, $limit: Int, $filter: FilterFindManyUserInput) {
    users(skip: $skip, limit: $limit, filter: $filter) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_QUESTIONS = gql`
  query GET_QUESTIONS {
    questions {
      _id
      title
      text
      createdAt
    }
  }
`;

export const CURRENT_CONFIG = gql`
  query CURRENT_CONFIG {
    currentSetting {
      _id
      carouselImages {
        _id
        title
        description
        url
        linkType
        link
      }
      orderLifespan
      scheduleMaxDays
      openHour
      closeHour
      pickupAddress
      supportUrl
    }
  }
`;

export const GET_TERMS_OF_SERVICE = gql`
  query GET_TERMS_OF_SERVICE {
    termsOfService {
      _id
      text
    }
  }
`;

export const GET_PLUGINS = gql`
  query GET_PLUGINS {
    plugins {
      _id
      name
      active
      credentials {
        name
        value
        type
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS {
    paymentMethods {
      _id
      title
      note
      commission
      active
      paymentData {
        _id
        name
        value
      }
      extraFields {
        _id
        name
        type
      }
      currency {
        _id
        name
      }
      taxes {
        _id
        name
        value
      }
    }
    currencies {
      _id
      name
    }
    taxes(filter: { active: true }) {
      _id
      name
    }
  }
`;

export const GET_DAILY_OVERVIEW = gql`
  query GET_DAILY_OVERVIEW($data: DailyOverviewInput) {
    dailyOverview(data: $data) {
      totalOrders
      totalClients
      paidOrders
      deliveredOrders
      canceledOrders
      income
      averageTicket
      currenciesDataset {
        labels
        data
      }
      salesPerHour {
        labels
        data
      }
    }
  }
`;

export const GET_PRODUCTS_DASHBOARD = gql`
  query GET_PRODUCTS_DASHBOARD($data: ProductsDashboardInput) {
    productsDashboard(data: $data) {
      productSales {
        labels
        data
      }
      productClients {
        labels
        data
      }
      totalIncome
      totalClients
    }
  }
`;

export const GET_GENERAL_ORDERS_DASHBOARD = gql`
  query GET_GENERAL_ORDERS_DASHBOARD {
    generalStats {
      usersCount
      usersGrowth
      ordersCount
      ordersGrowth
      income
      incomeGrowth
      productsCount
      productsGrowth
    }
  }
`;

export const GET_ORDER_DASHBOARD = gql`
  query GET_ORDER_DASHBOARD($date: String!) {
    ordersDashboard(date: $date) {
      todayOrders {
        _id
        code
        status
        stage
        total
        createdAt
        deliveryMethod
        client {
          name
        }
        rider {
          name
        }
        charge {
          method
        }
      }
      scheduledOrders {
        _id
        code
        status
        stage
        total
        createdAt
        schedule {
          date
          hour
          minute
        }
        client {
          name
        }
        rider {
          name
        }
        charge {
          method
        }
      }
    }
  }
`;

export const GET_SCHEDULED_ORDERS = gql`
  query GET_SCHEDULED_ORDERS($date: String!) {
    ordersDashboard(date: $date) {
      scheduledOrders {
        ...OrderFragment
      }
    }
  }
  ${OrderFragment}
`;

export const GET_SALES_DASHBOARD = gql`
  query GET_SALES_DASHBOARD($data: SalesDashboardInput) {
    salesDashboard(data: $data) {
      sales {
        labels
        data
      }
      categoriesIncome {
        labels
        data
      }
      productsIncome {
        labels
        data
      }
    }
  }
`;

export const GET_NEIGHBORHOODS = gql`
  query GET_NEIGHBORHOODS {
    getNeighborhoods {
      municipalities {
        name
        neighborhoods
      }
    }
  }
`;

export const LIVE_DASHBOARD = gql`
  query LIVE_DASHBOARD($data: LiveDashboardInput) {
    liveDashboard(data: $data) {
      todayOrders {
        ...OrderFragment
      }
      completedOrders {
        ...OrderFragment
      }
      cancelledOrders {
        ...OrderFragment
      }
    }
  }
  ${OrderFragment}
`;

export const GET_VENEZUELA = gql`
  query GET_VENEZUELA {
    getVenezuela {
      venezuela {
        municipio
        capital
        parroquias
        sectores
      }
    }
  }
`;

export const GET_DELIVERY_NOTE = gql`
  query GET_DELIVERY_NOTE($filter: FilterFindOneDeliveryNoteInput!) {
    deliveryNote(filter: $filter) {
      _id
      controlNumber
      paid
      charge {
        ...ChargeFragment
      }
      order {
        ...OrderFragment
      }
      createdAt
    }
    currencies(filter: { active: true }) {
      _id
      name
      rate
      symbol
    }
  }
  ${OrderFragment}
  ${ChargeFragment}
`;

export const GET_DELIVERY_NOTES = gql`
  query GET_DELIVERY_NOTES($skip: Int, $limit: Int) {
    deliveryNotes(skip: $skip, limit: $limit) {
      _id
      controlNumber
      paid
      createdAt
    }
  }
`;

export const GET_ORDER_TOTAL = gql`
  query GET_ORDER_TOTAL($data: GetOrderTotalInput) {
    getOrderTotal(data: $data) {
      subtotal
      total
      deliveryFee
      discount
      commission
      tax
      eta {
        date
        hour
        minute
      }
      preparationTime
      taxes {
        name
        value
      }
    }
  }
`;
