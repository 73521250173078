/* eslint-disable import/no-cycle */
import React from 'react';
import { useRouter } from 'next/router';
import {
  useQuery,
  useMutation,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';
import { CURRENT_USER } from '../graphql/queries';
import { SIGN_OUT } from '../graphql/mutations';
import { User } from '../models';
import { USER_PRIVILEGES } from '../lib/privileges';
import { useNotify } from '../hooks';

export type TUserContext = {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  refetch?: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      currentUser: User;
    }>
  >;
};

export const UserContext = React.createContext<TUserContext>({});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = React.useState<User>(null);
  const router = useRouter();
  const notify = useNotify();
  const [signOut] = useMutation(SIGN_OUT);

  const { data, loading, error, refetch } = useQuery<{ currentUser: User }>(
    CURRENT_USER,
    {
      fetchPolicy: 'network-only',
    }
  );

  const context = React.useMemo(
    () => ({ user, setUser, refetch }),
    [user, setUser, refetch]
  );

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setUser(data.currentUser);
      }
      if (error) {
        console.error(error);
        setUser(null);
        router.push('/log-in');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  React.useEffect(() => {
    if (user && user?.privilege === USER_PRIVILEGES.CLIENT) {
      notify('No cuenta con los permisos necesarios', 'error');
      signOut();
      router.push('/log-in');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
